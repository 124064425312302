<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-spin :spinning="loading">
          <h2 style="text-align: center;">{{ eventData.title }}</h2>
          <div style="text-align:right;">
            <a-button v-if="isShowCaozuo" type="primary" @click="handleDealEvent(eventData)" style="margin-right: 10px;">操作</a-button>
            <a-button @click="closeSeeInfo">关闭</a-button>
          </div>
          <div>
            <a-descriptions title="上报信息" :column="1" :bordered="true">
              <a-descriptions-item label="处理状态">
                <div style="width: 520px;">
                  <a-tag color="gold" v-if="eventData.status === 0">{{ eventData.statusName }}</a-tag>
                  <a-tag color="green" v-else-if="eventData.status === 1">{{ eventData.statusName }}</a-tag>
                  <a-tag color="pink" v-else-if="eventData.status === 3">{{ eventData.statusName }}</a-tag>
                  <a-tag v-else-if="eventData.status === 4">{{ eventData.statusName }}</a-tag>
                  <a-tag color="cyan" v-else>{{ eventData.statusName }}</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报人">
                <div style="width: 520px;">
                  <span v-if="eventData.reporterHeadPic !== ''"><a-avatar slot="avatar" size="small" :src="eventData.reporterHeadPic"/> {{ eventData.reporterName }}</span>
                  <span v-else><a-avatar slot="avatar" size="small">U</a-avatar> {{ eventData.reporterName }}</span>
                  <span style="margin-left: 10px;">{{ eventData.reporterTelephone }}</span>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报人身份" v-if="eventData.eventGroup === 'HealthEventRecord'"><div style="width: 520px;">{{ eventData.reporterIdentity }}</div></a-descriptions-item>
              <a-descriptions-item label="上报人电话" v-if="eventData.eventGroup === 'HealthEventRecord'"><div style="width: 520px;">{{ eventData.reporterPhone }}</div></a-descriptions-item>
              <a-descriptions-item label="所属小区"><div style="width: 520px;">{{ eventData.areaCodeName }} {{ eventData.communityName }}</div></a-descriptions-item>
              <a-descriptions-item label="发生时间"><div style="width: 520px;">{{ eventData.eventTime }}</div></a-descriptions-item>
              <a-descriptions-item label="事件类型"><div style="width: 520px;">{{ eventData.eventTypeName }}({{eventData.eventGroupName}})</div></a-descriptions-item>
              <a-descriptions-item label="事件级别"><div style="width: 520px;">{{ eventData.eventLevelName }}</div></a-descriptions-item>
              <a-descriptions-item label="来源"><div style="width: 520px;">{{ eventData.eventSourceName }}</div></a-descriptions-item>
              <a-descriptions-item label="上报内容"><div style="width: 520px;">{{ eventData.desc }}</div></a-descriptions-item>
              <a-descriptions-item label="附件">
                <div style="width: 520px;">
                  <p :key="index" v-for="(item, index) in eventData.attachmentsList">
                    <img
                      style="max-width: 100%"
                      :src="item"
                    />
                  </p>
                </div>
              </a-descriptions-item>
            </a-descriptions>
            <a-divider>处理信息</a-divider>
            <div v-for="item in dealData" :key="item.keyId">
              <a-descriptions :column="1" :bordered="true">
                <a-descriptions-item label="处理状态">
                  <div style="width: 520px;">
                    <a-tag color="gold" v-if="item.status === 0">{{ item.statusName }}</a-tag>
                    <a-tag color="green" v-else-if="item.status === 1">{{ item.statusName }}</a-tag>
                    <a-tag color="pink" v-else-if="item.status === 3">{{ item.statusName }}</a-tag>
                    <a-tag v-else-if="item.status === 4">{{ item.statusName }}</a-tag>
                    <a-tag color="cyan" v-else>{{ item.statusName }}</a-tag>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="审核人">
                  <div style="width: 520px;">
                    <span v-if="item.reporterHeadPic !== ''"><a-avatar slot="avatar" size="small" :src="item.reporterHeadPic"/> {{ item.reporterName }}</span>
                    <span v-else><a-avatar slot="avatar" size="small">U</a-avatar> {{ item.reporterName }}</span>
                    <span style="margin-left: 10px;">{{ item.reporterTelephone }}</span>
                  </div>
                </a-descriptions-item>
                <a-descriptions-item label="处理时间"><div style="width: 520px;">{{ item.createTime }}</div></a-descriptions-item>
                <a-descriptions-item label="备注"><div style="width: 520px;">{{ item.desc }}</div></a-descriptions-item>
                <a-descriptions-item label="附件">
                  <div style="width: 520px;">
                    <p :key="index" v-for="(items, index) in item.imageListData">
                      <img
                        width="100%"
                        :src="items.url"
                      />
                    </p>
                  </div>
                </a-descriptions-item>
              </a-descriptions>
              <a-divider :dashed="true" />
            </div>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center">
        <a-button v-if="isShowCaozuo" type="primary" @click="handleDealEvent(eventData)" style="margin-right: 10px;">操作</a-button>
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
      <deal-event
        ref="dealevent"
        :visible="visible"
        :eventDealData="eventDealData"
        :statusList="statusList"
        @cancel="handleCancel"
        @ok="handleOk"
      >
      </deal-event>
    </a-card>
  </a-drawer>
</template>

<script>
import { eventDealwithDetails, eventInfo } from '@/api/event'
import DealEvent from '@/views/event/components/DealEvent'
export default {
  name: 'SeeEvent',
  components: {
    DealEvent
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    },
    isShowCaozuo: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      seeInfoVisible: false,
      dealCheckStatus: false,
      labelCol: {
        lg: { span: 4 },
        sm: { span: 8 }
      },
      wrapperCol: {
        lg: { span: 20 },
        sm: { span: 17 }
      },
      loading: false,
      eventData: {},
      dealData: [],
      eventDealData: {},
      visible: false,
      keyId: 0,
      isDeal: false
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
      if (this.isDeal) {
        this.$emit('refreshData', 1)
      }
    },
    refreshData () {
      this.$emit('refreshData', 1)
    },
    eventInfoApi (keyId) {
      const _this = this
      _this.keyId = keyId
      _this.seeInfoVisible = true
      _this.loading = true
      eventInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.eventData = res.result.data
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
      _this.eventDealwithDetailsApi(keyId)
    },
    eventDealwithDetailsApi (keyId) {
      const _this = this
      eventDealwithDetails({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          _this.dealData = res.result.data
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    handleDealEvent (data) {
      this.isDeal = true
      this.eventDealData = data
      this.$refs.dealevent.getTokenApi()
      this.visible = true
    },
    handleOk () {
      this.visible = false
      this.eventInfoApi(this.keyId)
    },
    handleCancel (e) {
      this.eventDealData = {}
      console.log('handleCancel', e)
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>
