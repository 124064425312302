import request from '@/utils/request'
const baseURL = '/baseapi'
const EventApi = {
  EventList: '/admin/event/list',
  EventCondition: '/admin/event/condition',
  EventInfo: '/admin/event/eventInfo',
  SaveEventDeal: '/admin/event/saveEventDeal',
  EventDealwithDetails: '/admin/event/eventDealwithDetails',
  MyEvent: '/admin/event/myEvent',
  DelEvent: '/admin/event/delEvent',
  SaveEvent: '/admin/event/saveEvent',
  CancelEvent: '/admin/event/cancelEvent'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function eventList (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.EventList,
    method: 'post',
    data: parameter
  })
}

export function eventCondition () {
  return request({
    baseURL: baseURL,
    url: EventApi.EventCondition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function eventInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.EventInfo,
    method: 'post',
    data: parameter
  })
}

export function saveEventDeal (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.SaveEventDeal,
    method: 'post',
    data: parameter
  })
}

export function eventDealwithDetails (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.EventDealwithDetails,
    method: 'post',
    data: parameter
  })
}

export function myEvent (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.MyEvent,
    method: 'post',
    data: parameter
  })
}

export function delEvent (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.DelEvent,
    method: 'post',
    data: parameter
  })
}

export function saveEvent (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.SaveEvent,
    method: 'post',
    data: parameter
  })
}

export function cancelEvent (parameter) {
  return request({
    baseURL: baseURL,
    url: EventApi.CancelEvent,
    method: 'post',
    data: parameter
  })
}
